
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/setting',
  name: 'setting',
  meta: { title: '设置' },
  redirect: '/setting/platform/config',
  component: Main,
  children: [
    {
      path: '/setting/platform/config',
      name: 'platform',
      meta: {
        title: '平台设置',
        parentPath: '/setting',
        icon: 'icon-pingtaishezhi'
      },
      component: Blank,
      redirect: '/setting/platform/config',
      children: [{
        path: '/setting/platform/config',
        name: 'config',
        meta: {
          title: '平台信息',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/platform/config.vue')
      }, {
        path: '/setting/platform/keep_on_record',
        name: 'keep_on_record',
        meta: {
          title: '备案信息',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/platform/keep_on_record.vue')
      }]
    }, {
      path: '/setting/service/service',
      name: 'service',
      meta: {
        title: '客服设置',
        parentPath: '/setting',
        icon: 'icon-kefushezhi'
      },
      component: Blank,
      redirect: '/setting/service/service',
      children: [{
        path: '/setting/service/service',
        name: 'config',
        meta: {
          title: '联系客服',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/service/service.vue')
      }]
    }, {
      path: '/setting/system_maintain/journal',
      name: 'system_maintain',
      meta: {
        title: '系统维护',
        parentPath: '/setting',
        icon: 'icon-xitongweihu'
      },
      component: Blank,
      redirect: '/setting/system_maintain/journal',
      children: [{
        path: '/setting/system_maintain/journal',
        name: 'journal',
        meta: {
          title: '系统日志',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/journal.vue')
      }, {
        path: '/setting/system_maintain/cache',
        name: 'cache',
        meta: {
          title: '系统缓存',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/cache.vue')
      }, {
        path: '/setting/system_maintain/updata',
        name: 'updata',
        meta: {
          title: '系统升级',
          // hidden: true,
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/updata.vue')
      }, {
        path: '/setting/system_maintain/environment',
        name: 'environment',
        meta: {
          title: '系统环境',
          // hidden: true,
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/environment.vue')
      }, {
        path: '/setting/system_maintain/task',
        name: 'task',
        meta: {
          title: '定时任务',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/task/lists.vue')
      }, {
        path: '/setting/system_maintain/task_edit',
        name: 'task_edit',
        meta: {
          hidden: true,
          title: '定时任务',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/task/edit.vue')
      }]
    }
  ]
}]

export default routes
